import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Icon, IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import Loading from './Loading';

export default function SideDrawer(props) {
    const [state, setState] = React.useState(props.open);
    const [sheets, setSheets] = React.useState(props.sheet)
    useEffect(() => {
        setState(props.open)
        setSheets(props.sheet)
    }, [])

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450 }}
            role="presentation"
        >
            <div className='py-3 px-4 border-b flex justify-between items-center'>
                <h1 className='text-lg'>Google SpreadSheets</h1>
                <Tooltip title='refresh all spreadsheets'>
                    <IconButton
                        className={props?.refresh ? 'animate-spin' : ''}
                        onClick={() => props.updateAll()}>
                        <Icon color='success'>replay_circle_filled</Icon>
                    </IconButton>
                </Tooltip>
            </div>
            <List className={props?.refresh ? 'pointer-events-none opacity-30 bg-slate-100' : ''}>
                {props?.sheet?.map((text, index) => (
                    <ListItem key={text.id}>
                        <div className='w-full flex justify-between items-center'>
                            <h2 className='w-5/6 text-lg text-slate-600 break-words'>{text?.sheets?.fileName}</h2>
                            <Tooltip title={`refresh ${text?.sheets?.fileName}`}>
                                <IconButton
                                    className={text.refresh ? 'animate-spin' : ''}
                                    onClick={() => props.update(index, text?.sheetsId, text?.userId, text?.spreadSheetId)}>
                                    <Icon color='success'>refresh</Icon>
                                </IconButton>
                            </Tooltip>
                        </div>
                        {/* <ListItemText primary={text?.sheets?.fileName} /> */}
                    </ListItem>
                ))}
            </List>
            <Divider />
        </Box>
    );

    const Nodata = () => (
        <Box
            sx={{ width: 450, height: window.innerHeight }}
            role="presentation">
            <div className='h-full flex justify-center'>
                <Loading noData={true} />
            </div>
        </Box>
    )

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer
                        anchor={anchor}
                        open={state}
                        onClose={() => { setState(false); props.close(false) }}
                    >
                        {props?.sheet?.length ? list(anchor) : <Nodata />}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
