import { Button, Icon } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import http from '../services/http.service';

// var jwt = require('jsonwebtoken');
// import { jwt } from 'jsonwebtoken'
import jwt_decode from "jwt-decode";


export default function LoginResponse() {
    const params = useParams()
    const query = new URLSearchParams(window.location.search);
    const [status, setStatus] = React.useState(params?.status)

    const navigate = useNavigate()

    React.useEffect(() => {
        if(status === 'success') {
            const token = query.get('token')
            const gid = query.get('gid')
            const key = query.get('key')
            // decodeTokenSetLS(token, key).then(jwt => {
            //     console.log(jwt)
            // }).catch(e => {
            //     console.log(e)
            // })
            if(token && gid) {
                localStorage.setItem('sheet_access_token', token)
                localStorage.setItem('sheet_google_id', gid)
                navigate('/home')
            }
        }
    }, [])

    // function decodeTokenSetLS(token, key) {
    //     return new Promise((resolve, reject) => {
    //         try {
    //             var decoded = jwt_decode(token, key);
    //             console.log(decoded)
    //             resolve(decoded)
                
    //         }
    //         catch(e) {
    //             console.log(e)
    //         }
    //     })
    // }


    return (
        <div className='w-full h-full flex items-center justify-center'>
            {status === 'success' ?
                <div className='flex flex-col items-center'>
                    <Icon color='success' fontSize='30'>check_circle</Icon>
                    <p className='text-sm'>login success, please wait...</p>
                </div>
                :
                <div className='flex flex-col items-center'>
                    <Icon color='error' fontSize='30'>cancel</Icon>
                    <p className='text-sm'>failed to login</p>
                    {/* <Button>Retry</Button> */}
                </div>
            }
        </div>
    )
}