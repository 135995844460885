import { CircularProgress } from '@mui/material'
import * as React from 'react'


export default function Loading(props) {
    return (
        <div className='w-full h-full flex justify-center items-center'>
            { props.noData ? 'NO DATA FOUND' : <CircularProgress color="primary" size={20} /> }
        </div>
    )
}