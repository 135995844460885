import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const pages = [];
const settings = ['Logout'];

function ResponsiveAppBar(props) {
    const navigate = useNavigate('')
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [userImage, setUserImage] = React.useState('');

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        localStorage.removeItem('sheet_access_token')
        localStorage.removeItem('sheet_google_id')
        localStorage.removeItem('sheet_google_profile')
        localStorage.removeItem('sheet_google_admin')
        navigate('/')
        setAnchorElUser(null);
    };


    useEffect(() => {
        setUserImage(props?.user?.picture || localStorage.getItem('sheet_google_profile'))
    }, [])

    return (
        <AppBar position="static">
            <Toolbar className='px-5' disableGutters>
                {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                        mr: 2,
                        display: { xs: 'none', md: 'flex' },
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    MINI CRM
                </Typography>
                <Typography
                    variant="h5"
                    noWrap
                    component="a"
                    href=""
                    sx={{
                        mr: 2,
                        display: { xs: 'flex', md: 'none' },
                        flexGrow: 1,
                        fontFamily: 'monospace',
                        fontWeight: 700,
                        letterSpacing: '.3rem',
                        color: 'inherit',
                        textDecoration: 'none',
                    }}
                >
                    MINI CRM
                </Typography>
                <div className='flex-1'></div>
                <Button
                    className='mr-4'
                    onClick={() => { props.btnClick() }}
                    color='inherit'
                    endIcon={<Icon>article</Icon>}
                    variant='outlined'>
                    Spreadsheets
                </Button>
                {props.isadmin ?
                    <Button
                        className='mr-4'
                        onClick={() => { props.csv() }}
                        color='inherit'
                        endIcon={<Icon>download</Icon>}
                        variant='outlined'>
                        Download CSV
                    </Button>
                    : null
                }
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Logout">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar alt="A" src={userImage} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={() => setAnchorElUser(null)}
                    >
                        {settings.map((setting) => (
                            <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">{setting}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
export default ResponsiveAppBar;
