import * as React from 'react';
import { styled } from '@mui/material/styles';


import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow, { tableRowClasses } from '@mui/material/TableRow';
import { Routes, Route, useParams } from 'react-router-dom';
import http from '../services/http.service';
import ResponsiveAppBar from '../components/ResponsiveAppBar';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import AddSheet from '../dialogs/AddSheet';
import Loading from '../components/Loading';
import Filter from '../components/Filter';
import { Button, Fade, Icon, IconButton, Snackbar } from '@mui/material';
import { Box } from '@mui/system';
import SideDrawer from '../components/SideDrawer';

const admins = [
	'rajiv.ranjan@eulogik.com',
	'aptdatamngr@gmail.com',
	'amaptapp@gmail.com'
]

let stop = false //flag to control some function recalls

const TableCellBlack = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#263238',
		color: theme.palette.common.white,
		fontWeight: 'bold',
		textAlign: 'left'
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
	[`&.${tableRowClasses.head}`]: {
		backgroundColor: theme.palette.common.white,
		color: '#263238',
		fontSize: 14
	},
	[`&.${tableRowClasses.footer}`]: {
		fontSize: 14,
		backgroundColor: '#263238',
		color: theme.palette.common.white,
	},
}));

const columns = [
	{ id: 'name', label: 'Name', minWidth: 170 },
	{ id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
	{
		id: 'population',
		label: 'Population',
		minWidth: 170,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'size',
		label: 'Size\u00a0(km\u00b2)',
		minWidth: 170,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'density',
		label: 'Density',
		minWidth: 170,
		align: 'right',
		format: (value) => value.toFixed(2),
	},
];




const _columns = [
	{ key: 'sno', value: 'S.NO.', minWidth: 50 },
	{ key: 'name', value: 'Name', minWidth: 150 },
	{ key: 'mobile', value: 'Mobile No.', minWidth: 120 },
	{ key: 'currentClass', value: 'Current Class', minWidth: 150 },
	{ key: 'state', value: 'State', minWidth: 120 },
	{ key: 'city', value: 'City', minWidth: 120 },
	{ key: 'school', value: 'School', minWidth: 200 },
	{ key: 'board', value: 'Board', minWidth: 100 },
	{ key: 'session', value: 'Session', minWidth: 150 },
	{ key: 'status', value: 'Status', minWidth: 175 },
	{ key: 'remarks', value: 'Remarks', minWidth: 150 },
	{ key: 'teamLeader', value: 'TL Name', minWidth: 150 },
	{ key: 'alottedTo', value: 'Alloted to', minWidth: 150 },
	{ key: 'lastCallDate', value: 'Last Call Date', minWidth: 150 },
	{ key: 'timestamp', value: 'Timestamp', minWidth: 100 },
	{ key: 'seminarGivenBy', value: 'Seminar Given By', minWidth: 180 },
	{ key: 'seminarDate', value: 'Seminar Date', minWidth: 150 },
	{ key: 'source', value: 'Source', minWidth: 80 },
	{ key: 'stream', value: 'Stream', minWidth: 80 },
	{ key: 'nextCallDate', value: 'Next Call date', minWidth: 150 },
	{ key: 'agstStatus', value: 'AGST Reg\nStatus', minWidth: 180 },
	{ key: 'appDownloadStatus', value: 'App Download Status', minWidth: 180 },
	{ key: 'email', value: 'Email', minWidth: 150 },
	{ key: 'assignDate', value: 'Assign date', minWidth: 120 },
	{ key: 'medium', value: 'Medium', minWidth: 100 },
	{ key: 'altNumber', value: 'Alt No.', minWidth: 150 },
	{ key: 'altNumberSecond', value: '2nd Alt No.', minWidth: 150 }
]

let _filter = {
	status: '',
	stream: '',
	currentClass: '',
	source: '',
	medium: '',
	board: '',
	session: '',
	appDownloadStatus: '',
	teamLeader: '',
	alottedTo: '',
	search: ''
}

let filterApplied = false

export default function Home() {
	const { id } = useParams()
	const [sheet, setSheet] = React.useState(null);
	const [page, setPage] = React.useState(0);
	const [values, setValues] = React.useState([]);
	const [rowsPerPage, setRowsPerPage] = React.useState(100);
	const [user, setUser] = React.useState(null)
	const [total, setTotal] = React.useState(0)
	const [drawer, setDrawer] = React.useState(false)
	const [admin, setAdmin] = React.useState(false)
	const [refresh, setRefresh] = React.useState(false)
	const [snackbar, setSnackbar] = React.useState({
		open: false,
		message: 'please wait...',
		transistion: Fade
	})

	const _setSnackbar = (open, message) => {
		setSnackbar({
			open,
			message,
			transistion: Fade
		})
	}

	let skip = 0
	React.useEffect(() => {
		fetchUser()
	}, [])

	React.useEffect(() => {
		if (user?.id) fetchSheet(user)
	}, [user])

	React.useEffect(() => {
		if (user?.id && !stop) {
			console.log(stop)
			let filter = filterApplied ? `{
				"where": {
					${_filter.status}
					${_filter.session}
					${_filter.stream}
					${_filter.source}
					${_filter.medium}
					${_filter.currentClass}
					${_filter.board}
					${_filter.appDownloadStatus}
					${_filter.teamLeader}
					${_filter.alottedTo}
					${_filter.search}
					${admin ? `"userId": {"neq": ${null}}` : `"userId": "${user.id}"`}
					
				},
				"skip": ${0},
				"limit": ${rowsPerPage},
				"order": "createdAt DESC"
			}` : null
			fetchTableData(user.id, filter)
		}
	}, [rowsPerPage, admin, sheet])


	React.useEffect(() => {
		if (snackbar.open) {
			let timer = setTimeout(() => {
				let _snack = { ...snackbar }
				_snack.open = false
				setSnackbar(_snack)
				clearTimeout(timer)
			}, 1500);
		}
	}, [snackbar])

	async function fetchUser() {
		try {
			const gid = localStorage.getItem('sheet_google_id')
			const filter = `?filter={"where": {"googleId": "${gid}"}} `
			const user = await (await http.get(`/Users${filter}`)).data[0]
			if (user?.email && admins.includes(user?.email)) {
				localStorage.setItem('sheet_google_admin', true)
				setAdmin(true)
			}
			localStorage.setItem('sheet_google_profile', user?.picture)
			setUser(user)
		}
		catch (e) {
			console.log(e)
		}
	}


	async function fetchSheet(user) {
		try {
			// console.log(user)
			const filter = `?filter={${admin ? `"userId": {"neq": ${null}},` : `"where": {"userId": ${user?.id}},`} "include": "sheets"}`
			const response = await http.get(`/UserSheets${filter}`)
			// console.log(response.data)
			if (response && response.status == 200) {
				const _sheet = response.data?.map(element => {
					return { ...element, ...{ refresh: false } }
				})
				setSheet(_sheet)
				console.log(_sheet)
				// fetchTableData(user.id)
			}
		}
		catch (e) {

		}
	}

	async function fetchTableData(userId, _filter) {
		try {
			const filter = `{
				${admin ? `"where": {"userId": {"neq": ${null}}},` : `"where": {"userId": "${userId || user.id}"},`}
				"skip": ${skip},
				"limit": ${rowsPerPage}
            }`
			const response = await http.get('/SheetsData?filter=' + (_filter || filter))
			if (response.status === 200) {
				// console.log(response.data)
				fetchTotalData(JSON.parse(_filter || filter))
				setValues(response.data)
				let _snack = { ...snackbar }
				_snack.open = true
				_snack.message = _filter ? 'data filtered successfully!' : 'fetched successfully!'
				setSnackbar(_snack)
				if (_filter) filterApplied = true
				else filterApplied = false
			}
			else {
				let _snack = { ...snackbar }
				_snack.open = true
				_snack.message = 'something went wrong!'
				setSnackbar(_snack)
			}
		}
		catch (e) {
			console.log(e)
		}
	}


	async function xFilterClear() {
		_filter = {
			status: '',
			stream: '',
			currentClass: '',
			source: '',
			medium: '',
			board: '',
			session: '',
			appDownloadStatus: '',
			teamLeader: '',
			alottedTo: '',
			search: ''
		}
		fetchTableData()
	}

	const handleChangePage = (event, newPage) => {
		let filter = filterApplied ? `{
			"where": {
				${_filter.status}
				${_filter.session}
				${_filter.stream}
				${_filter.source}
				${_filter.medium}
				${_filter.currentClass}
				${_filter.board}
				${_filter.appDownloadStatus}
				${_filter.teamLeader}
				${_filter.alottedTo}
				${_filter.search}
				${admin ? `"userId": {"neq": ${null}}` : `"userId": "${user.id}"`}
			},
			"skip": ${skip},
			"limit": ${rowsPerPage},
			"order": "createdAt DESC"
		}` : null
		if (newPage === 0) {
			// RESET TO FIRST PAGE
			skip = 0
			// console.log(skip, rowsPerPage)
			fetchTableData(user.id, filter)
		}
		else {
			skip = newPage * rowsPerPage
			// console.log(skip, rowsPerPage)
			fetchTableData(user.id, filter)
		}
		// console.log(event)
		// console.log(newPage)
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		// console.log(event)
		setRowsPerPage(+event.target.value);
		setPage(0);
		skip = 0
	};

	const filter = (values, type) => {
		try {
			if (values.length) {
				console.log(values)
				if (type === 'status') {
					_filter[type] = `"status": {"inq": ${JSON.stringify(values)}},`
				}
				if (type === 'stream') _filter[type] = `"stream": {"inq": ${JSON.stringify(values)}},`
				if (type === 'currentClass') _filter[type] = `"currentClass": {"inq": ${JSON.stringify(values)}},`
				if (type === 'medium') _filter[type] = `"medium": {"inq": ${JSON.stringify(values)}},`
				if (type === 'board') _filter[type] = `"board": {"inq": ${JSON.stringify(values)}},`
				if (type === 'session') _filter[type] = `"session": {"inq": ${JSON.stringify(values)}},`
				if (type === 'source') _filter[type] = `"source": {"inq": ${JSON.stringify(values)}},`
				if (type === 'appDownloadStatus') _filter[type] = `"appDownloadStatus": {"inq": ${JSON.stringify(values)}},`
				if (type === 'alottedTo') _filter[type] = `"alottedTo": {"inq": ${JSON.stringify(values)}},`
				if (type === 'teamLeader') _filter[type] = `"teamLeader": {"inq": ${JSON.stringify(values)}},`
				if (type === 'search') {
					const _search = values + '%25'
					_filter[type] = `"or": [{"name": {"ilike": "${_search}"}}, {"mobile": {"ilike": "${_search}"}}, {"school": {"ilike": "${_search}"}}, {"city": {"ilike": "${_search}"}}] ,`
				}
			}
			else {
				_filter[type] = ''
			}
			const filter = `{
				"where": {
					${_filter.status}
					${_filter.session}
					${_filter.stream}
					${_filter.source}
					${_filter.medium}
					${_filter.currentClass}
					${_filter.board}
					${_filter.appDownloadStatus}
					${_filter.teamLeader}
					${_filter.alottedTo}
					${_filter.search}
					${admin ? `"userId": {"neq": ${null}}` : `"userId": "${user.id}"`}
				},
				"skip": ${skip},
				"limit": ${rowsPerPage},
				"order": "createdAt DESC"
			}`
			// console.log(filter)
			let _snack = { ...snackbar }
			_snack.open = true
			_snack.message = 'Filtering please wait...'
			setSnackbar(_snack)
			fetchTableData(user.id, filter)
		}
		catch (e) {
			console.log(e)
		}
	}

	async function fetchTotalData(where) {
		try {
			const _where = `where=${JSON.stringify(where?.where)}`
			const response = await http.get(`/SheetsData/count?${_where}`)
			if (response.status === 200) {
				setTotal(response.data.count)
			}
		}
		catch (e) {
			console.log(e)
		}
	}

	async function updateSheet(index, sheetsId, userId, spreadSheetId) {
		try {
			stop = true;
			let _sheet = [...sheet]
			_sheet[index]['refresh'] = true
			setSheet(_sheet)
			_setSnackbar(true, 'refreshing sheet data...')
			const googleId = localStorage.getItem('sheet_google_id')
			const body = {
				sheetsId, userId, spreadSheetId, googleId
			}
			const response = await http.post('/Users/updateSheet', body)
			stop = false
			if (response.status === 200) {
				_sheet[index]['refresh'] = false
				setSheet([..._sheet])
				console.log(_sheet[index])
				// fetchTableData()
				_setSnackbar(true, 'latest data updated')
			}
			else alert('failed to update')
		}
		catch (e) {
			console.log(e)
			alert('failed to update')
		}
	}

	async function _updateAllSheet() {
		try {
			setRefresh(true)
			_setSnackbar(true, 'refreshing sheet data...')
			const googleId = localStorage.getItem('sheet_google_id')
			const __sheet = sheet.map(_sheet => { return { spreadSheetId: _sheet.spreadSheetId, sheetId: _sheet.sheetsId } })
			const body = {
				googleId,
				sheets: __sheet
			}
			const response = await http.post('/Users/updateAllSheets', body)
			if (response.status === 200) {
				fetchTableData()
				_setSnackbar(true, 'latest data updated')
				setRefresh(false)
			}
			else alert('failed to update')
		}
		catch (e) {
			console.log(e)
			alert('failed to update')
			setRefresh(false)
		}
	}

	function _updateSheetPromise(index, sheetsId, userId, spreadSheetId) {
		return new Promise(async (resolve, reject) => {
			try {
				stop = true;
				let _sheet = [...sheet]
				_sheet[index]['refresh'] = true
				setSheet(_sheet)
				const googleId = localStorage.getItem('sheet_google_id')
				const body = {
					sheetsId, userId, spreadSheetId, googleId
				}
				const response = await http.post('/Users/updateSheet', body)
				stop = false
				if (response.status === 200) {
					_sheet[index]['refresh'] = false
					// setSheet([..._sheet])
					resolve([..._sheet])
				}
				else alert('failed to update')
			}
			catch (e) {
				reject(e)
			}
		})
	}

	async function updateAllSheet() {
		try {
			for (let index = 0; index < sheet.length; index++) {
				const _sheet = sheet[index];
				console.log(index, _sheet.sheetsId, _sheet.userId, _sheet.spreadSheetId)
				const __sheet = await _updateSheetPromise(index, _sheet.sheetsId, _sheet.userId, _sheet.spreadSheetId)
				if(index === sheet.length - 1) {
					_setSnackbar(true, 'latest data updated')
					setSheet(sheet)
				}
			}
		}
		catch (e) {
			console.log(e)
			alert('failed to update!')
		}
	}

	const downlaodCsv = async () => {
		try {
			const query = `?admin=${admin}&userId=${user?.id}`
			const dwnUrl = `${http.defaults.baseURL}/sheets-csv${query}`
			window.open(dwnUrl, `_blank`)
		}
		catch (e) {
			console.log(e)
			alert('failed to download csv')
		}
	}

	return (
		<div className='w-full h-full flex flex-col'>
			<ResponsiveAppBar
				user={user}
				isadmin={admin}
				btnClick={() => setDrawer(true)}
				csv={() => downlaodCsv()}
			/>
			{!sheet ? <Loading /> : sheet?.length === 0 ? <Loading noData={true} /> :
				<div className='px-5 py-4 flex flex-col flex-1'>
					<div className='mb-4 bg-white shadow-lg rounded'>
						<Filter
							admin={admin}
							name={user?.name}
							status={(e) => filter(e, 'status')}
							stream={(e) => filter(e, 'stream')}
							currentClass={(e) => filter(e, 'currentClass')}
							medium={(e) => filter(e, 'medium')}
							board={(e) => filter(e, 'board')}
							session={(e) => filter(e, 'session')}
							source={(e) => filter(e, 'source')}
							appDwnStatus={(e) => filter(e, 'appDownloadStatus')}
							teamLeader={(e) => filter(e, 'teamLeader')}
							alottedTo={(e) => filter(e, 'alottedTo')}
							onSearch={(e) => filter(e, 'search')}
							clear={() => xFilterClear()}
						/>
					</div>
					<div className='flex-1'>
						<Paper sx={{ width: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
							<TableContainer sx={{ maxHeight: 600, flex: 1 }}>
								<Table stickyHeader aria-label="sticky table">
									<TableHead>
										<TableRow>
											{_columns.map((column) => (
												<TableCellBlack
													key={column.key}
													align={'left'}
													style={{ minWidth: column.minWidth }}
												>
													{column.value}
												</TableCellBlack>
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{values.map((row) => {
											return (
												<TableRowCustom hover role="checkbox" tabIndex={-1} key={row.id}>
													{_columns.map((column) => {
														const value = row[column.key];
														return (
															<TableCell key={column.key} align={'left'}>
																{/* {column.format && typeof value === 'number'
																		? column.format(value)
																		: value} */}
																{value || '--'}
															</TableCell>
														);
													})}
												</TableRowCustom>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								style={{ backgroundColor: '#f5f5f5' }}
								rowsPerPageOptions={[100, 250, 500, 1000, { label: 'All', value: -1 }]}
								colSpan={3}
								component="div"
								count={total}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: {
										'aria-label': 'rows per page',
									},
									native: false,
								}}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</Paper>
					</div>
				</div>
			}
			<AddSheet done={() => fetchUser()} />
			{drawer ?
				<SideDrawer
					open={drawer}
					close={setDrawer}
					refresh={refresh}
					sheet={sheet}
					update={(index, sheetsId, userId, spreadSheetId) => updateSheet(index, sheetsId, userId, spreadSheetId)}
					updateAll={() => updateAllSheet()}
				/>
				:
				null
			}
			<Snackbar
				open={snackbar.open}
				onClose={() => setSnackbar({ open: false, message: '', transistion: Fade })}
				TransitionComponent={snackbar.transistion}
				message={snackbar.message}
				action={
					<Button color="inherit" size="small" onClick={() => setSnackbar({ open: false, message: '', transistion: Fade })}>
						OK
					</Button>
				}
			/>
		</div>
	)
}




function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <Icon>last_page</Icon> : <Icon>first_page</Icon>}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === 'rtl' ? <Icon>keyboard_arrow_right</Icon> : <Icon>keyboard_arrow_left</Icon>}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <Icon>keyboard_arrow_left</Icon> : <Icon>keyboard_arrow_right</Icon>}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <Icon>first_page</Icon> : <Icon>last_page</Icon>}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};