import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Upload from './screens/Upload';
import Home from './screens/Home';
import Sheets from './screens/Sheets';
import Auth from './screens/Auth';
import LoginResponse from './screens/LoginResponse';
import Table from './screens/Table';

import { blueGrey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
  },
});


export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className='w-full h-full'>
        <Router>
          <Routes>
            <Route path='/' element={<Auth />}></Route>
            <Route path='/upload-sheet' element={<Upload />}></Route>
            <Route path='/home' element={<Home />}></Route>
            <Route path='/sheets' element={<Table />}></Route>
            <Route path='/login-callback/:status' element={<LoginResponse />}></Route>
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  )
}