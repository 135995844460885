import { Button } from '@mui/material';
import * as React from 'react';
import http from '../services/http.service';


export default function Table() {

    React.useEffect(() => {
        const token = localStorage.getItem('sheet_access_token')
        if(token) {
            // REDIRECT
        }
        else {
            // AUTH WITH GOOGLE
        }
    }, [])

    const auth = async () => {
        try {
            const response = await http.post(`/Users/auth`, {})
            if(response.status === 200) {
                window.open(response.data, '_self')
            }
        }
        catch (e) {
            console.log(e)
        }
    }

    return (
        <div className='w-full h-full flex items-center justify-center'>
        </div>
    )
}