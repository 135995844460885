import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Icon from '@mui/material/Icon';
import http from '../services/http.service';
import { Link } from 'react-router-dom';


export default function Upload() {
    const [open, setOpen] = React.useState(true);
    const [isUploaded, setUploaded] = React.useState(false);
    const [showProgress, setShowProgress] = React.useState(false);
    const [progress, setProgress] = React.useState(1);

    const [file, setFile] = React.useState(null)


    const [creationProgress, setCreationProgress] = React.useState(1)
    const [isCreated, setCreated] = React.useState(null)




    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFile(null)
    };


    const submitHandler = () => {
        setOpen(false)
        setShowProgress(true)
        let body = {
            fileName: file[0]['name'],
            size: file[0]['size'],
        }
        let formData = new FormData()
        formData.append("file", file[0])
        const URL = `/uploads/sheets/upload`
        http.post(URL, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            },
        }).then(res => {
            if (res) {
                if (res.status === 200) {
                    body['name'] = res.data.result.files.file[0]['name']
                    setUploaded(true)
                    addSheet(body)
                }
                else alert('failed to upload file')
            }
        }).catch(err => {
            console.log(err)
            setUploaded(false)
        })
    }

    const addSheet = (body) => {
        let pro = 1
        let interval = setInterval(() => {
            pro++
            setCreationProgress(pro)
            if (pro === 92) {
                pro--
            }
        }, 1500);
        http.post('/SheetsData/add', body).then(res => {
            if (res.status === 200) {
                setCreated(true)
                clearInterval(interval)
                pro = 100
                setCreationProgress(100)
            }
            else {
                alert('failed to add sheet data')
            }
        })
    }

    return (
        <div className='w-full h-full flex items-center justify-center'>
            {showProgress ?
                <div>
                    <div className='w-96 py-4 px-5 bg-white shadow-md rounded-md'>
                        <div className='w-full flex justify-between items-center py-1'>
                            <p className='text-sm text-slate-500 font-semibold'>UPLOADING...</p>
                            <p className='text-sm text-slate-800 font-bold'>{progress}%</p>
                        </div>
                        <LinearProgress color={progress <= 25 ? 'warning' : progress > 25 && progress < 100 ? 'primary' : 'success'} variant="determinate" value={progress} />
                        {isUploaded ?
                            <div className='mt-4 flex flex-col justify-center items-center'>
                                <Icon color='success'>check_circle</Icon>
                                <p className='text-xs text-green-800'>Uploaded Successfully</p>
                            </div>
                            : null
                        }
                    </div>
                    {isUploaded ?
                        <div className='w-96 py-4 px-5 bg-white shadow-md rounded-md my-4'>
                            <div className='py-2 w-full'>
                                <div className='w-full flex justify-between items-center py-1'>
                                    <p className='text-sm text-slate-500 font-semibold'>CREATING DATA...</p>
                                    <p className='text-sm text-slate-800 font-bold'>{creationProgress}%</p>
                                </div>
                                <LinearProgress color={'primary'} variant="determinate" value={creationProgress} />
                            </div>
                            <div className='flex w-full mt-3 justify-end'>
                                <Link to={``}>
                                    <Button variant='contained' disabled={!isCreated} color='primary'>Go</Button>
                                </Link>
                            </div>
                        </div>
                        : null
                    }
                </div>
                :
                <Button variant="outlined" onClick={handleClickOpen}>
                    Upload Sheet
                </Button>
            }
            <Dialog open={open}>
                <DialogTitle>Select XLSX File</DialogTitle>
                <DialogContent>
                    <p className='text-gray-500 text-sm'>
                        Ensure to select <b>.xlsx</b> extenstion file, else it might not work properly.
                    </p>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="file"
                        type="file"
                        fullWidth
                        variant="standard"
                        onChange={(e) => setFile(e.target.files)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <Button variant='contained' disabled={!file} onClick={submitHandler}>Upload</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}



